import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content has-text-centered">
          <div className="columns is-centered">
            <div className="column is-half">
              <h1>Thank you!</h1>
              <p>We'll be in touch soon.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
